import { type PropsWithChildren, type FC, useState } from 'react';
import { HTTPError } from 'ky';
import { Helmet } from 'react-helmet';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { postContact } from 'domains/api/postContact';
import { type reasonType } from 'domains/types/contact';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

interface FormData {
  name: string;
  email: string;
  reason: reasonType;
  comment: string;
  isAgreed: boolean;
}

const ContactForm: FC<{
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setCompleted }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
      reason: 'service',
      comment: '',
      isAgreed: false,
    },
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!data.isAgreed) {
      return;
    }
    try {
      const response = await postContact(data);

      if (response.ok) {
        setCompleted(true);
        reset();
      }
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        const errorMessages = (await error.response.json()) as string[];
        errorMessages.forEach((errorMsg) => toast.error(errorMsg));
      }
    }
  };

  return (
    <form
      className="new_contact"
      id="new_contact"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="name">
              お名前
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          id="name"
          {...register('name', { required: true })}
        />
        {errors.name && (
          <div className="error_messages">お名前を入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="email">
              メールアドレス
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <input
          className="p-contact__input"
          type="email"
          id="email"
          {...register('email', { required: true })}
        />
        {errors.email && (
          <div className="error_messages">メールアドレスを入力してください</div>
        )}
      </div>
      <div className="p-contact__content">
        <p className="p-contact__title">
          <span>
            <label className="p-contact__titleMain" htmlFor="user_password">
              お問い合わせ項目
            </label>
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <div className="p-componentForm__row">
          <div className="p-componentForm__column">
            <div className="p-componentForm__radio">
              <div className="p-componentForm__radioSet">
                <input
                  className="p-componentForm__radioButton"
                  id="001-radio--01"
                  type="radio"
                  value="service"
                  {...register('reason', { required: true })}
                />
                <label
                  className="p-componentForm__radioLabel"
                  htmlFor="001-radio--01"
                >
                  サービス内容について
                </label>
              </div>
              <div className="p-componentForm__radioSet">
                <input
                  className="p-componentForm__radioButton"
                  id="001-radio--02"
                  type="radio"
                  value="price"
                  {...register('reason', { required: true })}
                />
                <label
                  className="p-componentForm__radioLabel"
                  htmlFor="001-radio--02"
                >
                  料金について
                </label>
              </div>
              <div className="p-componentForm__radioSet">
                <input
                  className="p-componentForm__radioButton"
                  id="001-radio--03"
                  type="radio"
                  value="other"
                  {...register('reason', { required: true })}
                />
                <label
                  className="p-componentForm__radioLabel"
                  htmlFor="001-radio--03"
                >
                  その他
                </label>
              </div>
            </div>
            {errors.reason && (
              <div className="error_messages">
                お問い合わせ項目を選択してください
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-componentForm__content">
        <p className="p-componentForm__title">
          <span className="p-componentForm__titleMain">お問い合わせ内容</span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <div className="p-componentForm__row">
          <div className="p-componentForm__column">
            <textarea
              className="p-componentForm__textarea"
              placeholder="本サービスの利用にあたって使える分析項目を教えてください。"
              {...register('comment', { required: true })}
            />
            {errors.reason && (
              <div className="error_messages">
                お問い合わせ内容を入力してください
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-componentForm__content">
        <p className="p-componentForm__title">
          <span className="p-componentForm__titleMain">
            個人情報の取り扱いについて
          </span>
          <span className="p-contact__titleSupplement">( 必須 )</span>
        </p>
        <div className="p-componentForm__row">
          <div className="p-componentForm__column">
            <p
              style={{
                textAlign: 'left',
                fontSize: '14px',
                marginBottom: '14px',
              }}
            >
              <a href="/privacy" style={{ fontSize: '14px' }}>
                プライバシーポリシー
              </a>
              をご確認いただき、同意の上お問い合わせください。
            </p>
            <div className="p-componentForm__checkbox">
              <div className="p-componentForm__checkboxSet">
                <input
                  className="p-componentForm__checkboxButton"
                  id="001-checkbox--01"
                  type="checkbox"
                  {...register('isAgreed')}
                />
                <label
                  className="p-componentForm__checkboxLabel"
                  htmlFor="001-checkbox--01"
                >
                  同意する
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-contact__content">
        <input
          type="submit"
          name="commit"
          value="お問い合わせする"
          className={`p-login__button ${
            dirtyFields.isAgreed != null && dirtyFields.isAgreed
              ? ''
              : 'button-disabled'
          }`}
          disabled={!(dirtyFields.isAgreed != null && dirtyFields.isAgreed)}
        />
      </div>
    </form>
  );
};

const ContactComplete: FC = () => {
  return (
    <>
      <header className="c-section__header--narrow">
        <h2 className="c-section__title">お問い合わせありがとうございます</h2>
        <p className="c-section__description">
          お問い合わせいただきました内容につきまして、
          <br className="u-br--mobileNone" />
          ご記入いただきましたメールアドレス宛にご連絡いたします。
          <br className="u-br--mobileNone" />
          3営業日以内にご連絡いたしますので、
          <br className="u-br--mobileNone" />
          もうしばらくお待ちください。
        </p>
      </header>
    </>
  );
};

const Contact: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測
  const [completed, setCompleted] = useState(false);

  return (
    <PublicLayout>
      <Helmet>
        <title>お問合せ: サイト分析、レポートはGrowth Support</title>
      </Helmet>

      <section
        className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-center"
        id="contact"
      >
        <div className="container p-login">
          {completed ? (
            <ContactComplete />
          ) : (
            <>
              {/* <header className="c-section__header--narrow"> */}
              {/*  <h2 className="c-section__title">お問い合わせ</h2> */}
              {/* </header> */}
              {/* <div className="c-section__body"> */}
              {/*  <div className="p-contact"> */}
              {/*    <div className="p-contact__container"> */}
              {/*      <ContactForm setCompleted={setCompleted} /> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </div> */}
              <div className="container" id="contact-form">
                <header className="c-section__header">
                  <h2 className="c-section__title">お問い合わせ</h2>
                  <p className="c-section__description">
                    サービスに関するお問い合わせは以下のメールアドレス宛にご連絡ください。
                    <br className="u-br--mobileNone" />※
                    ＠が全角になっているので半角に直してください。
                    <br className="u-br--mobileNone" />
                    info＠growth-support.net
                    <br className="u-br--mobileNone" />
                    3営業日以内にご連絡致します。
                  </p>
                </header>
              </div>
            </>
          )}
        </div>
      </section>
    </PublicLayout>
  );
};

export default Contact;
